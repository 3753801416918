import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";


// DRM
// globals 
global.preSale = false;
global.preSaleMintAllocation = 1; // default
global.preSaleFooter  = "General public sale.";
global.preSaleFooter2 = "";
global.preSaleTextQuantity = "";
// sigature is valid as it needs to be sent even when there is no presale so use any old one
global.preSaleSignature = "0x27e8783b7d780dbfc392c4f3fa54665ad63d3167716209a8157c3bca9cc9bb5a7b963e99f8a928d0ae465b55cfd997aa49c4de2bb18957b454887b1effd3a24d1c";
// DRM edits above

// congif json gas = 285000
// I have adjused that as it seems to use 50%, set to 40% discount


// PRSALE DATA
// data dump 
global.preSaleData = "";

// add this to BLACK X 1 https://opensea.io/collection/nft-mint-club/analytics
// 0x64Bc737b1A030aaC323c073f11939DB7b9e8F347


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

var StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

var StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

if (preSale == true)
StyledRoundButton = styled.button`
display: none;
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 370px;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  display: none;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;




function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click BUY to mint your NFT.`);
  


  // DRM - usestate can define mint amount for presale
  if (preSale == true)
    {

      preSaleFooter = "Presale access only.";
      preSaleTextQuantity = "NFT allocation = ";

      // load the data
      
      /*
      if (blockchain.account != null)
      {
        //alert("hello");
        preSaleMintAllocation = 5; 
      }
      */

      /*
      let check = function() {
        setTimeout(function () {
          if (blockchain.account === null)
            check();
          else {
            //alert(blockchain.account);
            preSaleMintAllocation = 5; 
          }
        }, 500);
      };
      check();
      */



    }


  // DRM edits above
  
  
  const [mintAmount, setMintAmount] = useState(preSaleMintAllocation);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    //let gasLimit = CONFIG.GAS_LIMIT;
    let gasLimit = 200000; // default

    // use history + 20% to be safe

    if (mintAmount == 1)   { gasLimit = 200000 }
    if (mintAmount >= 2)   { gasLimit = 180000 }
    if (mintAmount >= 5)   { gasLimit = 180000 }
    if (mintAmount >= 10)  { gasLimit = 180000 }
    if (mintAmount >= 20)  { gasLimit = 180000 }
  

    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount,preSaleSignature,preSaleMintAllocation)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        
        setFeedback(
          `Success: ${CONFIG.NFT_NAME} minted`
        );
        // DRM
        if (preSale == true)
        {
          setFeedback(
            `WARNING: Your ${CONFIG.NFT_NAME} is minted but you CANNOT mint again until the presale is over. If you do it will fail and you will be charged a gas fee.`
          ); 
        }

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
           // DRM
           // if presale true then remove buttons
           if (preSale == true)
           {
            StyledButton = styled.button`
            display: none;`
           }
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      
      
      dispatch(fetchData(blockchain.account));
      // DRM edits
      // this is where the data will be loaded
      // allocation
      // signature
      if (preSale == true)
      {

        var lines = "";
        var currentLine = "";
        var onTheList = false;
        var i = 0;
        var wallet = null;
        var signature = null;
        var allocation = null;

        // split the data by *'s
        lines = preSaleData.split("*");

        for(i in lines) {

          currentLine = lines[i];
          var line = currentLine.split("|");
          wallet         = line[0]
          signature      = line[1];
          allocation     = line[2]; 

          if (wallet.toUpperCase() === blockchain.account.toUpperCase())
          {
              onTheList = true;
              preSaleMintAllocation = allocation;
              preSaleSignature = signature;
              preSaleFooter2 = "You have been allocated " + preSaleMintAllocation + " NFT(s).";

              // DRM bit messy but it will do! 
              StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;



          }
        }

// test
//onTheList = false;

if (onTheList == false)
{
preSaleFooter2 = "Your wallet is not on the presale list.";
preSaleMintAllocation = 0;

StyledButton = styled.button`
display: none;
`;


}


      setMintAmount(preSaleMintAllocation);

      // if presale = true and user wallet not on
      // the list then it needs to stop or redirect
      // or remove the BUY buton etc


      }
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 10,
              border: "1px solid var(--secondary)",
              boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.3)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network: 
                      {CONFIG.PRESALE_MESSAGE}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();           
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {preSaleTextQuantity} {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >

                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Connect to (
            {CONFIG.NETWORK.NAME} network). We are currently minting On Chain Mondrian Genesis NFTs. 1 in 3
            mints will get a x2 refund on the mint costs. See our Discord for details via our homepage nftmintclub.com.
            Connected wallet [{blockchain.account}] {preSaleFooter} {preSaleFooter2}
              
        
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

// removed this from above
// We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
//successfully mint your NFT. We recommend that you don't lower the
//gas limit. 

export default App;
